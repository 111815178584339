<template>
	<div class="monte_creneaux">
		<div class="form-row align-items-center">
			<div class="col-lg-12 col-xl-auto mr-auto">
				<label class="mb-0" :for="'creneau_' + index">{{ $t('monte.creneau') }} {{ index }}</label>
			</div>
			<div class="col-5 col-md-3 col-sm-auto">
	            <input
	                class="form-control"
	                v-model="form.start"
	                type="text"
	                required
	            />
	        </div>
			<div class="col-auto mx-auto">
	            {{ $t('global.a') }}
	        </div>
			<div class="col-5 col-md-3 col-sm-auto mb-2 mb-md-0">
	            <input
	                class="form-control"
	                v-model="form.end"
	                type="text"
	                required
	            />
	        </div>
	        <div class="col">
	            <e-select
					v-model="form.stallion"
	                :options="stallion"
	                :searchable="true"
	                label="horse_nom"
				>	
					<template slot="singleLabel" slot-scope="{ option }">{{ option.horse_nom }}</template>
					<template slot="noOptions">{{ $t('global.list_empty') }}</template>
				</e-select>
			</div>
	        <div class="colauto ml-auto">
	            <b-button @click="deleteCreneau" variant="secondary btn-block">
					<font-awesome-icon :icon="['fal', 'trash-alt']" />
				</b-button>
			</div>
			<div class="col-auto ml-auto">
				<div class="btn btn-secondary ml-auto order-md-last">
	                <font-awesome-icon :icon="['fal', 'sort-alt']" />
	            </div>
	        </div>
		</div>
	</div>
</template>

<script type="text/javascript">

	export default {
		name: 'ConfigCreneauxEtalon',
		props: ['index', 'delete_stallion', 'stallion', 'start', 'end', 'horse', 'id'],
		data () {
			return {
				form: {
					start: null,
					end: null,
					id: null,
					stallion: {}
				},
			}
		},

		mounted() {
			this.init_component()
		},

		methods: {
			async init_component() {
				if(this.horse) {
					this.form.stallion = this.horse.planninghorse_stallion
					this.form.start = this.start
					this.form.end = this.end
					this.form.id = this.id
				}
			},

			deleteCreneau() {
				this.$emit('update:delete_creneau', this.index)
			},
		},

		components: {
			
		}
	}

</script>